<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="d-flex justify-end mb-6" flat tile>
        <v-breadcrumbs :items="breadcrumbs" large>
          <template v-slot:divider>
            <v-icon>{{ icons.mdiSlashForward }} </v-icon>
          </template>
        </v-breadcrumbs>
      </v-card>
      <v-card class="d-flex justify-end mb-6" flat tile>
        <v-card-text>
          <v-row class="justify-space-between">
            <v-col cols="4" md="3" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Tagihan').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Tagihan').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">UNPAID BILLS (IDR)</p>
                <h3 class="text-sm font-weight-semibold">- {{ tagihanidr | currency }}</h3>
              </div>
            </v-col>
            <v-col cols="4" md="3" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Tagihan').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Tagihan').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">UNPAID BILLS (USD)</p>
                <h3 class="text-sm font-weight-semibold">- {{ tagihandollar | dollar_currency }}</h3>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> VISAS</v-card-title>
          <v-btn color="accent" class="me-3 mt-4">
            <a href="visas/create">
              <v-icon color="#ECEFF1">
                {{ icons.mdiContentSave }}
              </v-icon>
            </a>
          </v-btn>
          <v-text-field
            class="pt-5"
            label="Search First Name"
            @keyup="doSearch()"
            v-model="search"
            dense
            outlined
          ></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">FIRST NAME</th>
                  <th class="text-center text-uppercase">LAST NAME</th>
                  <th class="text-center text-uppercase">TRANSACTION DATE</th>
                  <th class="text-center text-uppercase">PAY DATE</th>
                  <!-- <th class="text-center text-uppercase">PROOF OF PAYMENT</th> -->
                  <th class="text-center text-uppercase">PASSPORT NUMBER</th>
                  <th class="text-center text-uppercase">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayData()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ data.nama_depan }}</td>
                  <td class="text-center">{{ data.nama_belakang }}</td>
                  <td class="text-center">
                    {{ data.tgl_transaksi | date }}
                  </td>

                  <!-- <td class="text-center">
                    {{ new Date(data.tgl_bayar) | date }}
                  </td> -->
                  <td class="text-center">
                    <v-chip v-if="data.status_bayar === 'L'" class="ma-2" color="info">{{
                      new Date(data.tgl_bayar) | date
                    }}</v-chip>
                    <v-chip v-else class="ma-2" color="error" text-color="white"> UNPAID</v-chip>
                  </td>
                  <td class="text-center">
                    {{ data.nomor_passport }}
                  </td>
                  <td class="text-center">
                    <span v-if="data.status_bayar === 'BB'">
                      <v-btn
                        small
                        :to="{
                          name: 'visas-pembayaran',
                          params: {
                            id_visa: data.id_visa,
                          },
                        }"
                        color="success"
                        class="me-3 mt-4"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiCash }}
                        </v-icon>
                      </v-btn>
                      <!-- <v-btn
                        small
                        @click="navigateToTransaksiCreateOrPembayaran(data)"
                        color="success"
                        class="mt-4"
                        type="button"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiCash }}
                        </v-icon>
                      </v-btn> -->
                    </span>
                    <span v-if="data.status_bayar === 'L'">
                      <v-btn
                        small
                        :to="{
                          name: 'visas-invoice',
                          params: {
                            id_visa: data.id_visa,
                            id_transaksi: data.id_transaksi,
                          },
                        }"
                        color="primary"
                        class="me-3 mt-4"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiPrinter }}
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-else>
                      <v-btn
                        small
                        :to="{
                          name: 'visas-tagihan',
                          params: {
                            id_visa: data.id_visa,
                            id_transaksi: data.id_transaksi,
                          },
                        }"
                        color="secondary"
                        class="me-3 mt-4"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiPrinter }}
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-if="data.status_bayar === 'BB'">
                      <v-btn
                        small
                        :to="{
                          name: 'visas-edit',
                          params: {
                            id_visa: data.id_visa,
                            id_member: data.id_member,
                            id_transaksi: data.id_transaksi,
                          },
                        }"
                        color="warning"
                        class="me-3 mt-4"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiGreasePencil }}
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-if="data.status_bayar === 'BB'">
                      <v-btn
                        small
                        @click="deletePost(data.id_visa, data.id_transaksi, data.id_member)"
                        color="error"
                        class="mt-4"
                        type="button"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="8">
                    <center>
                      <div class="v-data-footer">
                        <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                        <span class="v-data-footer__icons-before" bis_skin_checked="1">
                          <button
                            type="button"
                            @click="page--"
                            :class="[
                              'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page == 1 ? 'v-btn--disabled' : '',
                            ]"
                            aria-label="Previous page"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                        <span
                          :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                          v-for="pageNumber in pages.slice(page - 1, page + 5)"
                          :key="pageNumber"
                          @click="page = pageNumber"
                        >
                          <!-- <a href="#">{{ pageNumber }}</a> -->
                          <button
                            type="button"
                            class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                            aria-label="Next page"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>
                        <span class="v-data-footer__icons-after" bis_skin_checked="1">
                          <button
                            type="button"
                            :class="[
                              'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page < pages.length ? '' : 'v-btn--disabled',
                            ]"
                            aria-label="Next page"
                            @click="page++"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                      </div>
                    </center>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiPrinter,
  mdiCash,
  mdiSlashForward,
  mdiHome,
} from '@mdi/js'

export default {
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }
      if (data === 'Tagihan') return { icon: mdiCurrencyUsd, color: 'error' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Visas',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiPrinter,
        mdiCash,
        mdiSlashForward,
        mdiHome,
      },

      visas: [],
      tagihanidr: [],
      tagihandollar: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  created() {
    this.$isLoading(true)
    this.getAllData()
    this.getDataTagihan()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getDataTagihan() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/dashboard'
      return this.axios.get(url).then(response => {
        this.tagihanidr = response.data.tagihanidr
        this.tagihandollar = response.data.tagihandollar
      })
    },
    async navigateToTransaksiCreateOrPembayaran(data) {
      // if (data.tipe_bayar === 'paymentgateway') {
      //   return this.$router.push({
      //     name: 'visas-transaksi',
      //     params: { id_member: data.id_member, id_visa: data.id_visa },
      //   })
      // }
      return this.$router.push({
        name: 'visa.pembayaran',
        params: { id_visa: data.id_visa },
      })
    },
    deletePost(id_visa, id_transaksi, id_member) {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriDelete = process.env.VUE_APP_ROOT_API + `/api/visas/${id_visa}/${id_transaksi}/${id_member}`
            // console.log(this.uriDelete)
            this.axios
              .delete(uriDelete)
              .then(response => {
                this.visas.splice(this.visas.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Visa deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  window.location.reload()
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann delete visa!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'visa failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              })

            setTimeout(() => {
              this.$router.push({ name: 'visas-index' })
            }, 3000)
          }
        })
    },
    getAllData() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/visas'
      return this.axios.get(url).then(response => {
        this.visas = response.data.visa
        // console.log('NAMA ROLE: ' + response.data.users.role_rel.nama_role)
      })
    },

    doSearch() {
      let go = this.visas.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_depan.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.visas)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.visas.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(visas) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return visas.slice(from, to)
    },
  },

  watch: {
    visas() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
